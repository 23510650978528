import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    quotationForm: {
        quotation_id: "",
        vehicle_category_id: "",
        vehicle_sub_category_id: "",
        vehicle_sub_category: "",
        customer_type: "",
        carrier_type: "",
        vehicle_type_id: "",
        vehicle_type: "",
        body_type_id: "",
        body_type: "",
        make_id: "",
        make: "",
        type: "",
        vehicle_owner_type: "",
        uses: "",
        model_id: "",
        model: "",
        fuel_id: "",
        fuel: "",
        sub_model_id: "",
        sub_model: "",
        rto_id: "",
        rto_code: "",
        registration_date: "",
        cc: "",
        policy_type_id: "",
        policy_type_name: "",
        seating_capacity: null,
        previous_policy_available: null,
        previous_policy_id: "",
        previous_policy: null,
        previous_policy_expiry_date: "",
        previous_policy_od_expiry_date: "",
        previous_policy_tp_expiry_date: "",
        previous_claim: "",
        previous_ncb: "",
        is_cng_fitted: "",
        agent_id: "",
    },

}

export const quotationSlice = createSlice({
    name: 'quotation',
    initialState: initialState,
    reducers: {
        setFormValue: (state, action) => {
            let key = action.payload.key;
            let value = action.payload.value;
            if (state['quotationForm']) {
                state['quotationForm'][key] = value
            }

        },

        setQuotationId: (state, action) => {
            state.quotationId = action.payload
        },

        setQuotation: (state, action) => {
            state.quotation = action.payload
        },

        emptyQuotationId: (state) => {
            state.quotationId = ""
        },

        emptyQuotation: (state) => {
            state.quotationForm = {
                vehicleCategoryId: "",
                vehicle_sub_category_id: "",
                vehicle_sub_category: "",
                customer_type: "",
                carrier_type: "",
                vehicle_type_id: "",
                vehicle_type: "",
                body_type_id: "",
                body_type: "",
                make_id: "",
                make: "",
                type: "",
                vehicle_owner_type: "",
                uses: "",
                model_id: "",
                model: "",
                fuel_id: "",
                fuel: "",
                sub_model_id: "",
                sub_model: "",
                rto_id: "",
                rto_code: "",
                registration_date: "",
                cc: "",
                seating_capacity: null,
                previous_policy_available: null,
                previous_policy_id: "",
                previous_policy: null,
                previous_policy_expiry_date: "",
                previous_policy_od_expiry_date: "",
                previous_policy_tp_expiry_date: "",
                previous_claim: "",
                previous_ncb: "",
                is_cng_fitted: "",
                agent_id: "",
                proof_of_rc: "",
                proof_of_rc_img_front: "",
                proof_of_rc_img_front_path: "",
                proof_of_rc_img_back: "",
                proof_of_rc_img_back_path: "",
                proof_of_previous_policy: "",
                proof_of_previous_policy_img_front: "",
                proof_of_previous_policy_img_front_path: "",
                proof_of_previous_policy_img_back: "",
                proof_of_previous_policy_img_back_path: "",
                policy_type_id: "",
                policy_type_name: "",
                proof_of_photo: "Photo",
                proof_of_photo_img_front: "",
                proof_of_photo_img_front_path: "",
                proof_of_photo_img_back: "",
                proof_of_photo_img_back_path: "",
                other_documents: [],
                selected_cover_options: [],
            }
        },

    },
})


export const { setFormValue, setQuotationId, setQuotation, emptyQuotation, emptyQuotationId } = quotationSlice.actions


export default quotationSlice.reducer
