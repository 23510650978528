import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';

import counterSlice from './reducers/counterSlice';
import { combineReducers } from 'redux';


import { configureStore } from '@reduxjs/toolkit';
import purposalSlice from './reducers/agents/purposalSlice';
import quotationSlice from './reducers/agents/quotationSlice';
import MotorUnderWritingSlice from './reducers/agents/MotorUnderWritingSlice';
import sidebarShowSlice from './reducers/agents/sidebarShowSlice';
import  quotationFormSlice  from './reducers/agents/quotationFormSlice';


const persistConfig = {
  key: 'root',
  storage,
}




const counterPersistConfig = {
  key: 'counter',
  storage,
}

const purposalPersistConfig = {
  key: 'counter',
  storage,
}

const quotationPersistConfig = {
  key: 'counter',
  storage,
}

const motorUnderWritingPersistConfig = {
  key: 'counter',
  storage,
}

const sliderPersistConfig = {
  key: 'sidebarShow',
  storage,
}


const quotationFormPersistConfig = {
  key: 'quotationForm',
  storage,
}



const rootReducer = combineReducers({
  counter: persistReducer(counterPersistConfig, counterSlice),
  purposal: persistReducer(purposalPersistConfig, purposalSlice),
  quotation: persistReducer(quotationPersistConfig, quotationSlice),
  motorunderwriting: persistReducer(motorUnderWritingPersistConfig, MotorUnderWritingSlice),
  sidebarShow: persistReducer(sliderPersistConfig, sidebarShowSlice)

})




// const persistedReducer = persistReducer(persistConfig, counterSlice)
const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, logger]


});


export const persistor = persistStore(store)
