import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    MotorUnderWritingForm: {
        dealName: "",
        brokerId: "",
        brokerCode: "",
        vehicleCategoryId: "",
        vehicleCategoryTitle: "",
        vehicleTypeId: null,
        uses: null,
        vehicleOwnerType: null,
        types: null,
        bodyType: "",
        insurer: "",
        policyType: null,
        ccMin: "",
        ccMax: "",
        gvwMin: "",
        gvwMax: "",
        seatMin: "",
        seatMax: "",
        state: [],
        rto: [],
        vehicleSubCategory: null,
        make: [],
        model: "",
        fuel: "",
        age: "",
        ncbId: null,
        cpaId: null,
        cpaTitle: null,
        breakInWaiverId: null,
        breakInWaiverTitle: null,
        suppliers: null,
        payInId: "",
        payInTitle: "",
        payInTpPer: "",
        payInOdPer: "",
        payOutId: "",
        payOutTitle: "",
        discountType: "",
        discountPer: "",
        membershipPlansPayOut: [],

        IsBodyTypeApply: false,
        isCc: false,
        isGvw: false,
        isSeat: false,
        isTp: false,
        isOd: false,
        isPayInTp: false,
        isPayInOd: false,
        isPayOutTp: false,
        isPayOutOd: false,
    },

}

export const MotorUnderWritingSlice = createSlice({
    name: 'motorunderwriting',
    initialState: initialState,
    reducers: {
        setFormValue: (state, action) => {
            let key = action.payload.key;
            let value = action.payload.value;
            if (state['MotorUnderWritingForm']) {
                state['MotorUnderWritingForm'][key] = value
            }

        },

        setdealId: (state, action) => {
            state.dealId = action.payload
        },

        emptydealId: (state) => {
            state.dealId = ""
        },


        emptyMotor: (state) => {
            state.MotorUnderWritingForm = {
                dealName: "",
                brokerId: "",
                brokerCode: "",
                vehicleCategoryId: "",
                vehicleCategoryTitle: "",
                vehicleTypeId: null,
                uses: null,
                vehicleOwnerType: null,
                types: null,
                bodyType: "",
                insurer: "",
                policyType: null,
                ccMin: "",
                ccMax: "",
                gvwMin: "",
                gvwMax: "",
                seatMin: "",
                seatMax: "",
                state: [],
                rto: [],
                vehicleSubCategory: null,
                make: [],
                model: "",
                fuel: "",
                age: "",
                ncbId: null,
                cpaId: "",
                cpaTitle: "",
                breakInWaiverId: "",
                breakInWaiverTitle: "",
                suppliers: null,
                payInId: "",
                payInTitle: "",
                payInTpPer: "",
                payInOdPer: "",
                payOutId: "",
                payOutTitle: "",
                discountType: "",
                discountPer: "",
                membershipPlansPayOut: [],

                IsBodyTypeApply: false,
                isCc: false,
                isGvw: false,
                isSeat: false,
                isTp: false,
                isOd: false,
                isPayInTp: false,
                isPayInOd: false,
                isPayOutTp: false,
                isPayOutOd: false,
            }
        },

    },
})


export const { setFormValue, setdealId, emptyMotor, emptydealId } = MotorUnderWritingSlice.actions


export default MotorUnderWritingSlice.reducer
